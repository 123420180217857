*{
    box-sizing: border-box;
}
body{
    margin: 0;
   
}

/* Header */

#head {
    box-sizing: border-box;
    display: flex;
    color : black;
    align-items: center;
    width : 100%;
    padding: .6em;
    background: linear-gradient(to right,red,hotpink,purple, blue);
}

#logo {
    font-size: 2em;
    margin-inline: 2em;
}

#link > a{
   text-decoration: none;
   color : black;
   font-size: 1.2em;
}
#movies {
    margin-right: 1em;
}

/* serach bard banner  */

#image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-image: url('./img/movie\ banner.jpeg');
    object-fit: cover;
    width: 100%;
    max-width: 1400px;
    height: calc(100vh / 2.5);
    max-height: 360px;
}

#search{
    display: flex;
    width: 100%;
    position: relative;
    margin: 20px;
}
#search-bar{
    width: 100%;
    height: 46px;
    border-radius: 30px;
    font-size: 1.1em;
    border: none;
    margin: 0;
    text-indent: 20px;
    /* on focus no border appears  */
    outline: none;  
}


#search-btn {
    position: absolute;
    right: 0px;
    border-radius: 30px;
    font-size: 1.1em;
    padding: 10px 20px;
    border: none;
    height: 46px;
    background-image: linear-gradient(to right,violet, blue);
    color: white;
}

#search-btn:hover{
    cursor: pointer;
}

#parent{
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: 0 50px;
}