#movie, #tvshow{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    margin-left: 50px;
    margin-right: 50px;
    overflow-y: hidden;
   
}

/* Link tab */

.link{
    display: flex;
    align-items: center;
    margin-left: 50px;
}
.link > p{
    font-size: 1.5rem;
    margin-right: 20px;
}
.link-movie{
    border: 1px solid rgb(3, 37, 65);
    border-radius: 30px;
    font-size: 1.25rem;
    display: flex;

}

.link-movie > a {
    text-decoration: none;
    padding: 4px 20px;   
}
.not-active{
    color: black;
}
.active{
    background-color: tomato;
    color: white;
    background-color: black;
    border-radius: 30px;
}

