
  .grid-item {
    /* border : 1px solid black; */
    margin : 5px;
    position: relative;
  }
  
  .grid-item-poster {
    width : 150px;
    height : 225px;
    border-radius: 20px;
  }
  
  .grid-item-title{
    width : 150px;
    height : 66px;
  }

  .grid-item-title{
    margin-top : 10px;
  }

  .grid-item-title > p{
    margin: 0;
    /* border: 1px solid red; */
  }

  /* rating */

  #icon{
    /* width: fit-content; */
    height: fit-content;
    padding:0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    gap: .2rem;
    background: rgba(19,19,19, .7);
    padding: .2rem;
    border-top-right-radius: 20px;
  }
  
  #icon > img{
    width: 1rem;
    height: 1rem;
  }
#icon > span{
  color: white;
}